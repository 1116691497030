/**
 * 卡密操作日志相关API 
 */

import request from '@/utils/request'

/**
* 查询套餐卡密操作日志列表
* @param params
* @returns {Promise<any>}
*/
export function comboPage(params) {
  return request({
    url: `seller/card/cardsKeyOperationLog/comboPage`,
    method: 'post',
    params
  })
}

/**
* 导出卡券操作日志
* @param params
* @returns {Promise<any>}
*/
export function exportOut(params) {
  return request({
    url: `seller/card/cardsKeyOperationLog/exportOut`,
    method: 'post',
    params
  })
}